import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    ref: "file",
    type: "file",
    accept: "image/*",
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uploadImage($event))),
    id: "file-input",
    style: {"display":"none"}
  }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */))
}