import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2efa38d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "cancelAccountContainer" }
const _hoisted_2 = {
  href: "http://www.wearetesters.com/contacto",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('Testers_settings_cancelaccount_close_acc')), 1 /* TEXT */),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('Testers_settings_cancelaccount_next_buttom')), 1 /* TEXT */),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('Testers_settings_cancelaccount_recomendations')) + " ", 1 /* TEXT */),
      _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$t('Testers_settings_cancelaccount_contact_area')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelAccount()))
      }, _toDisplayString(_ctx.$t('Testers_settings_cancelaccount_cancel_acc')), 1 /* TEXT */),
      _withDirectives(_createElementVNode("p", null, _toDisplayString(_ctx.$t('Error_Try_Again')), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, _ctx.cancelAccountForm.showError]
      ])
    ])
  ]))
}